import ErrorBoundary from '@components/ErrorBoundary/ErrorBoundary'
import { GlobalError } from '@components/ErrorBoundary/Errors/GlobalError'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router'
import type { UserData } from 'src/server/types'

export function AuthRoutes() {
  const isLogged = useSelector((s: { user: UserData }) => s.user)
  return (
    <ErrorBoundary errorComponent={GlobalError}>
      {isLogged ? <Outlet /> : <Navigate to="/login" />}
    </ErrorBoundary>
  )
}

export function NotAuthRoutes() {
  const isAuthenticated = useSelector((s: { user: UserData }) => s.user)
  return (
    <ErrorBoundary errorComponent={GlobalError}>
      {!isAuthenticated ? <Outlet /> : <Navigate to="/" />}
    </ErrorBoundary>
  )
}

export function AdminRoutes() {
  const user = useSelector((s: { user: UserData }) => s.user)
  const isAdmin = user && user.isAdmin
  return (
    <ErrorBoundary errorComponent={GlobalError}>
      {isAdmin ? <Outlet /> : <Navigate to="/" />}
    </ErrorBoundary>
  )
}
