import { NavLink, Outlet } from 'react-router-dom'

import './AdminNav.css'

export default function AdminNav() {
  return (
    <>
      <nav id="kpi-admin-nav" className="container">
        <NavLink
          to="/admin/kpis"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          KPIs
        </NavLink>
        <NavLink
          to="/admin/roles"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Roles
        </NavLink>
        <NavLink
          to="/admin/users"
          className={({ isActive }) => (isActive ? 'active' : '')}
        >
          Usuarios
        </NavLink>
      </nav>
      <Outlet />
    </>
  )
}
