import type { KpiMetadata } from 'src/server/types'
import { Suspense, useEffect, useRef, useState } from 'react'
import EditTable from './EditTable/EditTable'
import { createPortal } from 'react-dom'
import EditDialog, { EditDialogRef } from './EditDialog/EditDialog'
import { Icons } from '@static/icons'
import RemoveDialog from './RemoveDialog/RemoveDialog'
import { useKpisPanel } from '@hooks/useApi'
import { FormattedMessage } from '@components/Intl/FormattedMessage'

import './EditMetadatas.css'

export default function EditMetadatas() {
  const emptyKpi: KpiMetadata = {
    category: null,
    formula: null,
    isNatural: false,
    isNegative: false,
    isVisible: true,
    kpiFrequency: null,
    subcategory: null,
    target: null,
    targetFormula: null,
    title: null,
    unit: null,
    warehouse: null
  }

  const [editDialogData, setEditDialogData] = useState<{
    kpi: KpiMetadata
    type: 'create' | 'edit'
  }>({
    kpi: emptyKpi,
    type: 'create'
  })
  const [removeDialog, setRemoveDialog] = useState<KpiMetadata>(null)
  const [showDialogs, setShowDialogs] = useState<boolean>(false)

  const { createKpi, refreshKpis, removeKpi, updateKpi } = useKpisPanel()

  const editDialogRef = useRef<EditDialogRef>()
  const removeDialogRef = useRef<EditDialogRef>()

  const handleRemove = async () => {
    await removeKpi(removeDialog.id!.toString())
    await refreshKpis()
    removeDialogRef.current.closeModal()
  }

  const handleOpenCreate = () => {
    setEditDialogData({ kpi: emptyKpi, type: 'create' })
    editDialogRef.current.showModal()
  }

  const handleOpenRemoveDialog = (kpi: KpiMetadata) => {
    setRemoveDialog(kpi)
    removeDialogRef.current.showModal()
  }

  const handleOpenEditDialog = (kpi: KpiMetadata) => {
    setEditDialogData({ kpi, type: 'edit' })
    editDialogRef.current.showModal()
  }

  const handleSubmit = async (newKpi: KpiMetadata) => {
    const call = editDialogData.type === 'create' ? createKpi : updateKpi
    await call(newKpi)
    await refreshKpis()
    editDialogRef.current.closeModal()
  }

  useEffect(() => {
    setShowDialogs(true)
  }, [])

  return (
    <main id="kpi-edit-metadatas" className="container">
      <div>
        <h1>
          <FormattedMessage id="edit.title" />
        </h1>
        <button type="button" onClick={handleOpenCreate}>
          <img src={Icons.crossIcon.source} />
          <FormattedMessage id="edit.create" />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="edit.table.head.title" />
            </th>
            <th>
              <FormattedMessage id="edit.table.head.category" />
            </th>
            <th>
              <FormattedMessage id="edit.table.head.target" />
            </th>
            <th>
              <FormattedMessage id="edit.table.head.unit" />
            </th>
          </tr>
        </thead>
        <Suspense fallback={<tbody />}>
          <EditTable
            removeDialog={handleOpenRemoveDialog}
            showDialog={handleOpenEditDialog}
          />
        </Suspense>
      </table>
      {showDialogs
        ? createPortal(
            <EditDialog
              handleSubmit={handleSubmit}
              ref={editDialogRef}
              closeModal={() => {
                setEditDialogData({ kpi: emptyKpi, type: 'create' })
                editDialogRef.current.closeModal()
              }}
              kpi={editDialogData.kpi}
            />,
            document.body
          )
        : null}
      {showDialogs
        ? createPortal(
            <RemoveDialog
              ref={removeDialogRef}
              title={removeDialog?.title}
              removeKpi={handleRemove}
              closeModal={() => {
                setRemoveDialog(null)
                removeDialogRef.current.closeModal()
              }}
            />,
            document.body
          )
        : null}
    </main>
  )
}
