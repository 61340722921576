import { useRoles } from '@hooks/useApi'
import { Icons } from '@static/icons'

interface EditRoleMetadataProps {
  handleShowDialog: (data: string) => void
}

export default function EditRoleMetadata({
  handleShowDialog
}: EditRoleMetadataProps) {
  const data = useRoles()

  return (
    <tbody>
      {data.map((entry) => (
        <tr key={entry}>
          <td>{entry}</td>
          <td className="buttons">
            <img
              className={entry === 'ADMIN' ? 'disabled' : ''}
              src={Icons.editIcon.source}
              alt={Icons.editIcon.alt}
              role="button"
              aria-label="Editar KPI"
              onClick={() => handleShowDialog(entry)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}
