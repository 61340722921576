import { Units } from 'src/server/types'
import clock from './clock.svg'
import currency_euro from './currency_euro.svg'
import days from './days.svg'
import helix from './helix.svg'
import kg from './kg.svg'
import operator from './operator.svg'
import percentage from './percentage.svg'
import pieces from './pieces.svg'
import reclamation from './reclamation.svg'
import security from './security.svg'
import ton from './ton.svg'
import units from './units.svg'
import accidents from './accidents.svg'
import paddles from './paddles.svg'
import ratio from './ratio.svg'
import incidents from './incidents.svg'
import turn from './turn.svg'

export const UnitsIcons: Record<Units, string> = {
  ton,
  clock,
  percentage,
  currency_euro,
  kg,
  performance: percentage,
  units,
  helix,
  paddles,
  days,
  turn,
  ratio,
  reclamation,
  operator,
  pieces,
  security,
  bathroom: ton,
  sand: ton,
  incidents,
  accidents
}
