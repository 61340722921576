import Metric from '@components/Intl/Metric/Metric'
import { useKpisActivity, useKpisWithPendingData } from '@hooks/useApi'
import { useMetrics } from '@hooks/useMetrics'
import metricsUtils from '@utils/metrics'
import { useEffect, useMemo, useState } from 'react'
import utils from '@utils/utils'
import { Kpi } from 'src/server/types'
import timeUtils from '@utils/time'
import { useOutletContext } from 'react-router-dom'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import { useAppSelector } from 'universal-scripts'

const { getMetricsWithValues, orderMetrics } = metricsUtils

export default function MetricsContainer() {
  const { reset } = useOutletContext<{ reset: number }>()
  const { addPendingMetric, center, changeFreqScroll } = useMetrics()

  const metrics = useKpisActivity(center)
  const pendingKpis = useKpisWithPendingData()

  const selectedWarehouse = useAppSelector((s) => s.warehouse)

  const filteredMetrics = useMemo(
    () => metricsUtils.filterByWarehouse(metrics, selectedWarehouse),
    [metrics, selectedWarehouse]
  )

  const orderedMetrics = useMemo(
    () => orderMetrics(filteredMetrics, 'weekly'),
    [filteredMetrics]
  )

  const initialValues = useMemo(
    () => getMetricsWithValues(filteredMetrics, center),
    [filteredMetrics, center]
  )

  const [values, setValues] =
    useState<Record<number, Omit<Kpi, 'id'>>>(initialValues)

  const firstOMonthFreqIndex = orderedMetrics.findIndex(
    (metric) => metric.kpiFrequency === 'monthly'
  )
  const firstOWeekFreqIndex = orderedMetrics.findIndex(
    (metric) => metric.kpiFrequency === 'weekly'
  )

  useEffect(() => {
    setValues(initialValues)
  }, [initialValues, reset])

  useEffect(() => {
    const headerHeight = document
      .querySelector('header')
      .getBoundingClientRect().height
    const pillsHeight = document
      .querySelector('.pills')
      .getBoundingClientRect().height

    const handler = () => {
      const month = document.querySelector('.first-month')
      if (month.getBoundingClientRect().top < pillsHeight + headerHeight + 16) {
        changeFreqScroll('monthly')
      } else {
        changeFreqScroll('weekly')
      }
    }

    const throttledHandler = utils.debounce(handler, 200)

    window.addEventListener('scroll', throttledHandler)
    return () => window.removeEventListener('scroll', throttledHandler)
  }, [changeFreqScroll])

  const handleChangeValue = (id: number, value: number) => {
    const copyValues = structuredClone(values)
    const element = copyValues[id]
    copyValues[id].value = value
    addPendingMetric(id, element)
    setValues(copyValues)
  }

  const findIsMissing = (id: number) => {
    const pendingKpi = pendingKpis.find((kpi) => kpi.id === id)
    if (!pendingKpi) return false
    const { missing_slots, kpiFrequency } = pendingKpi
    const slotFreq = kpiFrequency === 'monthly' ? 'M' : 'W'
    const date = new Date(center)
    const week = timeUtils.getWeekByMs(center).toString().padStart(2, '0')
    const month = timeUtils.getMonthByMs(center).toString().padStart(2, '0')
    return missing_slots.includes(
      `${date.getUTCFullYear()}-${slotFreq}${slotFreq === 'M' ? month : week}`
    )
  }

  if (orderedMetrics.length === 0) {
    return (
      <p>
        <FormattedMessage id="dashboad.empty" />
      </p>
    )
  }

  return (
    <ul>
      {orderedMetrics.map((metric, index) => (
        <li
          key={metric.id}
          className={
            index === firstOMonthFreqIndex
              ? 'first-month'
              : index === firstOWeekFreqIndex
                ? 'first-week'
                : ''
          }
        >
          <Metric
            isMissingValue={findIsMissing(metric.id)}
            metric={metric}
            value={values[metric.id]?.value}
            handleChangeValue={handleChangeValue}
          />
        </li>
      ))}
    </ul>
  )
}
