import { forwardRef, useImperativeHandle, useRef } from 'react'
import { FormattedMessage } from 'react-intl'

import './RemoveDialog.css'

interface EditDialogProps {
  title: string
  closeModal: () => void
  removeKpi: () => void
}

export interface EditDialogRef {
  showModal: () => void
  closeModal: () => void
}

const RemoveDialog = forwardRef<EditDialogRef, EditDialogProps>(
  ({ title, closeModal, removeKpi }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>(null)

    useImperativeHandle(ref, () => ({
      showModal: () => dialogRef.current.showModal(),
      closeModal: () => dialogRef.current.close()
    }))

    return (
      <dialog id="kpi-remove-dialog" ref={dialogRef}>
        <section>
          <h2>
            <FormattedMessage
              id="edit.remove.title"
              values={{ kpi: <span>{title}</span> }}
            />
          </h2>
          <div>
            <button className="cancel" onClick={closeModal}>
              <FormattedMessage id="edit.remove.cancel" />
            </button>
            <button className="remove" onClick={removeKpi}>
              <FormattedMessage id="edit.remove.remove" />
            </button>
          </div>
        </section>
      </dialog>
    )
  }
)

RemoveDialog.displayName = 'EditDialog'

export default RemoveDialog
