import { useUserRoles } from '@hooks/useApi'
import { Icons } from '@static/icons'

interface EditRoleUserProps {
  handleShowDialog: (data) => void
}

export default function EditRoleUser({ handleShowDialog }: EditRoleUserProps) {
  const data = useUserRoles()

  return (
    <tbody>
      {data.map((entry) => (
        <tr key={entry.id}>
          <td>{entry.username}</td>
          <td>{entry.email}</td>
          <td>{entry.rolename}</td>
          <td className="buttons">
            <img
              src={Icons.editIcon.source}
              alt={Icons.editIcon.alt}
              role="button"
              aria-label="Editar Usuario"
              onClick={() => handleShowDialog(entry)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}
