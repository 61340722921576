import { forwardRef, ReactNode, useImperativeHandle, useRef } from 'react'

export interface DialogMethodsRef {
  closeModal: () => void
  showModal: () => void
}

interface DialogProps {
  children: ReactNode
  id?: string
  className?: string
}

const Dialog = forwardRef<DialogMethodsRef, DialogProps>(
  ({ children, className = null, id = null }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>()

    useImperativeHandle(ref, () => ({
      closeModal: () => dialogRef.current.close(),
      showModal: () => dialogRef.current.showModal()
    }))

    return (
      <dialog ref={dialogRef} id={id} className={className}>
        {children}
      </dialog>
    )
  }
)

Dialog.displayName = 'Dialog'

export default Dialog
