import { useKpisPanelData } from '@hooks/useApi'
import { Icons } from '@static/icons'
import { UnitsIcons } from '@static/icons/units/index'
import { FormattedMessage } from 'react-intl'
import { KpiMetadata } from 'src/server/types'

interface EditTableProps {
  showDialog: (kpi: KpiMetadata) => void
  removeDialog: (kpi: KpiMetadata) => void
}

export default function EditTable({
  showDialog,
  removeDialog
}: EditTableProps) {
  const kpis = useKpisPanelData()
  return (
    <tbody>
      {kpis.map((kpi) => (
        <tr key={kpi.id.toString()}>
          <td aria-label={kpi.title}>{kpi.title}</td>
          <td>
            <FormattedMessage id={`overview.filter.${kpi.category}.name`} />
          </td>
          <td className="target">{kpi.target ?? '-'}</td>
          <td className="units">
            <img src={UnitsIcons[kpi.unit]} alt={'icon'} />
          </td>
          <td className="buttons">
            <img
              src={Icons.editIcon.source}
              alt={Icons.editIcon.alt}
              role="button"
              aria-label="Editar KPI"
              onClick={() => showDialog(kpi)}
            />
            <img
              src={Icons.trashIcon.source}
              alt={Icons.trashIcon.alt}
              role="button"
              aria-label="Eliminar KPI"
              onClick={() => removeDialog(kpi)}
            />
          </td>
        </tr>
      ))}
    </tbody>
  )
}
