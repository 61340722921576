import CustomSelect from '@components/CustomSelect/CustomSelect'
import Dialog, { DialogMethodsRef } from '@components/Dialog/Dialog'
import { FormattedMessage } from '@components/Intl/FormattedMessage'
import {
  FormEvent,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { createPortal } from 'react-dom'
import EditRoleUser from './EditRoleUser/EditRoleUser'
import {
  useRoles,
  useUpdateUserRoles,
  useUserRolesRefresh
} from '@hooks/useApi'
import { Roles, UserAccountDto } from 'src/server/types'

import './RoleUserDialog.css'

function OptionComponent({
  option,
  selected
}: {
  option: { value: string; label: string }
  selected: boolean
}) {
  return <span className={selected ? 'selected' : ''}>{option.label}</span>
}

function RoleLabelComponent({ label }: { label: string }) {
  return (
    <div>
      {label ? (
        <span>{label}</span>
      ) : (
        <span>
          <FormattedMessage id="admin.role-metadata.dialog.empty" />
        </span>
      )}
      <span className="icon-arrow" />
    </div>
  )
}

function Select({
  selectedRole,
  handleChange
}: {
  selectedRole: string
  handleChange: (value: string) => void
}) {
  const roles = useRoles()

  const options = useMemo(
    () => roles.map((role) => ({ label: role, value: role })),
    [roles]
  )

  return (
    <div className="select-wrapper">
      <CustomSelect
        OptionComponent={OptionComponent}
        LabelComponent={<RoleLabelComponent label={selectedRole} />}
        onChange={{
          add: handleChange
        }}
        options={options}
        value={selectedRole}
      />
      <input
        className="hidden"
        name="role"
        type="text"
        defaultValue={selectedRole}
        required
      />
    </div>
  )
}

export default function RoleUser() {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [selectedUserRole, setSelectedUserRole] =
    useState<UserAccountDto | null>(null)
  const [selectedRole, setSelectedRole] = useState<string | null>(null)
  const [error, setError] = useState<boolean>(false)
  const dialogRef = useRef<DialogMethodsRef>()

  const refreshData = useUserRolesRefresh()
  const update = useUpdateUserRoles()

  useEffect(() => {
    setShowDialog(true)
  }, [])

  const handleShowDialog = (data: UserAccountDto) => {
    setSelectedUserRole(data)
    setError(false)
    dialogRef.current.showModal()
  }

  const closeModal = () => {
    dialogRef.current.closeModal()
    setError(false)
    setSelectedUserRole(null)
    setSelectedUserRole(null)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setError(false)
    try {
      await update({ ...selectedUserRole, rolename: selectedRole as Roles })
      await refreshData()
      dialogRef.current.closeModal()
      setSelectedRole(null)
      setSelectedUserRole(null)
    } catch (err) {
      setError(true)
    }
  }

  return (
    <>
      <main id="kpi-role-user" className="container">
        <div>
          <h1>
            <FormattedMessage id="admin.role-user.title" />
          </h1>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="admin.role-user.table.user" />
              </th>
              <th>
                <FormattedMessage id="admin.role-user.table.mail" />
              </th>
              <th>
                <FormattedMessage id="admin.role-metadata.rol" />
              </th>
            </tr>
          </thead>
          <Suspense fallback={<tbody />}>
            <EditRoleUser handleShowDialog={handleShowDialog} />
          </Suspense>
        </table>
      </main>
      {showDialog
        ? createPortal(
            <Dialog ref={dialogRef} className="kpi-role-user-dialog">
              <form onSubmit={handleSubmit}>
                <header>
                  <button type="submit">
                    <FormattedMessage id="edit.dialog.edit.header.apply" />
                  </button>
                  <div
                    role="button"
                    onClick={closeModal}
                    aria-label="Close Filters"
                  >
                    <button className="close"></button>
                  </div>
                </header>
                <h2>{selectedUserRole?.username}</h2>
                <section>
                  <p>
                    <FormattedMessage id="admin.role-user.dialog.description" />
                  </p>
                  <Suspense
                    fallback={
                      <CustomSelect
                        OptionComponent={OptionComponent}
                        LabelComponent={
                          <RoleLabelComponent
                            label={selectedUserRole?.rolename}
                          />
                        }
                        onChange={{
                          add: () => null
                        }}
                        options={[]}
                        value={selectedUserRole?.rolename}
                      />
                    }
                  >
                    <Select
                      handleChange={(value) => {
                        setSelectedRole(value)
                      }}
                      selectedRole={selectedRole}
                    />
                  </Suspense>
                </section>
                {error ? (
                  <p>
                    <FormattedMessage id="error.global.title" />
                    <FormattedMessage id="error.global.subtitle" />
                  </p>
                ) : null}
              </form>
            </Dialog>,
            document.body
          )
        : null}
    </>
  )
}
