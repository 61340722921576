import CustomSelect from '@components/CustomSelect/CustomSelect'
import {
  FormEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { categoriesData } from 'src/data/categories'
import { KpiMetadata, Units } from 'src/server/types'
import { UnitsIcons } from '@static/icons/units'

import './EditDialog.css'
import { Frequency } from 'src/types'

function OptionComponent({
  option,
  selected
}: {
  option: { value: string; label: string }
  selected: boolean
}) {
  return <span className={selected ? 'selected' : ''}>{option.label}</span>
}

function CategoryLabelComponent({ label }: { label: string }) {
  return (
    <div>
      {label ? (
        <span>
          <FormattedMessage id={`overview.filter.${label}.name`} />
        </span>
      ) : (
        <span>
          <FormattedMessage id="edit.category.empty" />
        </span>
      )}
      <span className="icon-arrow" />
    </div>
  )
}

function SubcategoryLabelComponent({ label }: { label: string }) {
  return (
    <div>
      <span>
        {label ? (
          <FormattedMessage
            id={`overview.filter.production.subcategories.${label}`}
          />
        ) : null}
      </span>
      <span className="icon-arrow" />
    </div>
  )
}

function UnitLabelComponent({ label }: { label: string }) {
  return (
    <div>
      {label ? (
        <span>
          <FormattedMessage id={`edit.units.${label}`} />
        </span>
      ) : (
        <span>
          <FormattedMessage id="edit.units.empty" />
        </span>
      )}
      <span className="icon-arrow" />
    </div>
  )
}

interface EditDialogProps {
  kpi: KpiMetadata
  handleSubmit: (newKpi: KpiMetadata) => void
  closeModal: () => void
}

export interface EditDialogRef {
  showModal: () => void
  closeModal: () => void
}

const EditDialog = forwardRef<EditDialogRef, EditDialogProps>(
  ({ kpi, handleSubmit, closeModal }, ref) => {
    const dialogRef = useRef<HTMLDialogElement>(null)
    const [editedKpi, setEditedKpi] = useState<Omit<KpiMetadata, 'id'>>(kpi)
    const categories = categoriesData.map((category) => category.name)
    const subcategories = categoriesData
      .map((category) => category.subcategories)
      .flat()
    const intl = useIntl()
    const categoriesSelect = useMemo(
      () =>
        categories.map((category) => ({
          label: intl.formatMessage({ id: `overview.filter.${category}.name` }),
          value: category
        })),
      [categories]
    )
    const subcategoriesSelect = useMemo(
      () =>
        subcategories.map((subcategory) => ({
          label: intl.formatMessage({
            id: `overview.filter.production.subcategories.${subcategory}`
          }),
          value: subcategory
        })),
      [subcategories]
    )
    const units = useMemo(() => {
      const labels = Object.keys(UnitsIcons)
      return labels.map((name) => ({
        label: intl.formatMessage({ id: `edit.units.${name}` }),
        value: name
      }))
    }, [])

    useImperativeHandle(ref, () => ({
      showModal: () => dialogRef.current.showModal(),
      closeModal: () => dialogRef.current.close()
    }))

    useEffect(() => {
      setEditedKpi(kpi)
    }, [kpi])

    const handleTopSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleSubmit(editedKpi)
    }

    return (
      <dialog id="kpi-edit-dialog" ref={dialogRef}>
        <form onSubmit={handleTopSubmit}>
          <header>
            <button type="submit">
              <FormattedMessage id="edit.dialog.edit.header.apply" />
            </button>
            <div role="button" onClick={closeModal} aria-label="Close Filters">
              <button className="close"></button>
            </div>
          </header>
          <section>
            <div>
              <label htmlFor="title">
                <FormattedMessage id="edit.table.head.title" />
              </label>
              <input
                name="title"
                required
                placeholder="Titulo"
                type="text"
                value={editedKpi.title ?? ''}
                onChange={({ target: { value } }) =>
                  setEditedKpi((prev) => ({ ...prev, title: value }))
                }
              />
            </div>
            <div>
              <label>
                <FormattedMessage id="edit.table.head.warehouse" />
              </label>
              <div
                className="warehouse"
                onChange={(e) => {
                  const target = e.target as HTMLInputElement
                  if (target.name === 'warehouse') {
                    setEditedKpi((prev) => ({
                      ...prev,
                      warehouse: target.value.toUpperCase()
                    }))
                  }
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="fundilusa"
                    name="warehouse"
                    value="fundilusa"
                    required
                  />
                  <label htmlFor="fundilusa">
                    <FormattedMessage id="header.flag.fundilusa" />
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="fundimaroc"
                    name="warehouse"
                    value="fundimaroc"
                  />
                  <label htmlFor="fundimaroc">
                    <FormattedMessage id="header.flag.fundimaroc" />
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="target">
                <FormattedMessage id="edit.table.head.target" />
              </label>
              <input
                required
                step="any"
                name="target"
                type="number"
                placeholder="Objetivo"
                value={editedKpi.target ?? ''}
                onChange={({ target: { value } }) =>
                  setEditedKpi((prev) => ({ ...prev, target: Number(value) }))
                }
              />
            </div>
            <div>
              <label htmlFor="natural">
                <FormattedMessage id="edit.table.head.nature" />
              </label>
              <input
                name="natural"
                type="checkbox"
                checked={editedKpi.isNatural ?? false}
                onChange={() =>
                  setEditedKpi((prev) => ({
                    ...prev,
                    isNatural: !prev.isNatural
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="category">
                <FormattedMessage id="edit.table.head.category" />
              </label>
              <CustomSelect
                OptionComponent={OptionComponent}
                LabelComponent={
                  <CategoryLabelComponent label={editedKpi.category} />
                }
                options={categoriesSelect}
                value={editedKpi.category}
                onChange={{
                  add: (value) =>
                    setEditedKpi((prev) => ({ ...prev, category: value }))
                }}
              />
              <input
                className="hidden"
                name="category"
                type="text"
                defaultValue={editedKpi.category ?? ''}
                required
              />
            </div>
            <div>
              <label htmlFor="negative">
                <FormattedMessage id="edit.table.head.negative" />
              </label>
              <input
                name="negative"
                type="checkbox"
                checked={editedKpi.isNegative ?? false}
                onChange={() =>
                  setEditedKpi((prev) => ({
                    ...prev,
                    isNegative: !prev.isNegative
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="subcategory">
                <FormattedMessage id="edit.table.head.subcategory" />
              </label>
              {editedKpi.category !== 'production' ? (
                <div className="disabled-select" />
              ) : (
                <>
                  <CustomSelect
                    OptionComponent={OptionComponent}
                    LabelComponent={
                      <SubcategoryLabelComponent
                        label={editedKpi.subcategory}
                      />
                    }
                    options={subcategoriesSelect}
                    value={editedKpi.subcategory}
                    onChange={{
                      add: (value) =>
                        setEditedKpi((prev) => ({
                          ...prev,
                          subcategory: value
                        }))
                    }}
                  />
                  <input
                    className="hidden"
                    name="subcategory"
                    type="text"
                    defaultValue={editedKpi.subcategory ?? ''}
                    required
                  />
                </>
              )}
            </div>
            <div>
              <label>
                <FormattedMessage id="edit.table.head.frequency" />
              </label>
              <div
                className="frequency"
                onChange={(e) => {
                  const target = e.target as HTMLInputElement
                  if (target.name === 'frequency') {
                    setEditedKpi((prev) => ({
                      ...prev,
                      kpiFrequency: target.value as Frequency
                    }))
                  }
                }}
              >
                <div>
                  <input
                    type="radio"
                    id="weekly"
                    name="frequency"
                    value="weekly"
                    required
                  />
                  <label htmlFor="weekly">
                    <FormattedMessage id="edit.dialog.edit.frequency.weekly" />
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="monthly"
                    name="frequency"
                    value="monthly"
                  />
                  <label htmlFor="monthly">
                    <FormattedMessage id="edit.dialog.edit.frequency.monthly" />
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="units">
                <FormattedMessage id="edit.table.head.unit" />
              </label>
              <CustomSelect
                OptionComponent={OptionComponent}
                LabelComponent={<UnitLabelComponent label={editedKpi.unit} />}
                onChange={{
                  add: (value) =>
                    setEditedKpi((prev) => ({ ...prev, unit: value as Units }))
                }}
                options={units}
                value={editedKpi.unit}
              />
              <input
                className="hidden"
                name="units"
                type="text"
                defaultValue={editedKpi.unit ?? ''}
                required
              />
            </div>
          </section>
        </form>
      </dialog>
    )
  }
)

EditDialog.displayName = 'EditDialog'

export default EditDialog
