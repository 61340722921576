import { FormattedMessage } from '@components/Intl/FormattedMessage'
import {
  FormEvent,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import EditRoleMetadata from './EditRoleMetadata/EditRoleMetadata'
import { createPortal } from 'react-dom'
import Dialog, { DialogMethodsRef } from '@components/Dialog/Dialog'
import {
  useKpisPanelData,
  usePanelRoleMetadata,
  useRoleMetadata
} from '@hooks/useApi'
import Toggle from '@components/Filters/Toggle/Toggle'

import './RoleMetadata.css'
import './RoleMetadataDialog.css'

interface KpisByRoleProps {
  role: string
  addUpdatedItems: (id: number) => void
  removeUpdatedItem: (id: number) => void
}

function KpisByRole({
  role,
  addUpdatedItems,
  removeUpdatedItem
}: KpisByRoleProps) {
  const metadataWithRole = useRoleMetadata()
  const kpis = useKpisPanelData()

  const [kpisWithRoles, setKpisWithRoles] = useState<
    Map<number, { isIncluded: boolean; title: string }>
  >(() => {
    const map = new Map<number, { isIncluded: boolean; title: string }>()
    kpis.forEach((kpi) => {
      map.set(kpi.id, {
        isIncluded: role
          ? metadataWithRole.some(
              (item) => item.metadata_id === kpi.id && item.rolename === role
            )
          : false,
        title: kpi.title
      })
    })
    return map
  })

  useEffect(() => {
    setKpisWithRoles(() => {
      const map = new Map<number, { isIncluded: boolean; title: string }>()
      kpis.forEach((kpi) => {
        map.set(kpi.id, {
          isIncluded: role
            ? metadataWithRole.some(
                (item) => item.metadata_id === kpi.id && item.rolename === role
              )
            : false,
          title: kpi.title
        })
        const call = map.get(kpi.id).isIncluded
          ? addUpdatedItems
          : removeUpdatedItem
        call(kpi.id)
      })
      return map
    })
  }, [role, kpis, metadataWithRole, addUpdatedItems, removeUpdatedItem])

  const handleChange = (id: number) => {
    const copy = new Map(kpisWithRoles)
    copy.set(id, {
      ...kpisWithRoles.get(id),
      isIncluded: !kpisWithRoles.get(id).isIncluded
    })
    const call = copy.get(id).isIncluded ? addUpdatedItems : removeUpdatedItem
    setKpisWithRoles(copy)
    call(id)
  }

  return (
    <ul>
      {Array.from(kpisWithRoles.keys()).map((id) => (
        <li key={id}>
          <span>{kpisWithRoles.get(id).title}</span>
          <Toggle
            value={kpisWithRoles.get(id).isIncluded}
            handleChange={() => handleChange(id)}
          />
        </li>
      ))}
    </ul>
  )
}

export default function RoleMetadata() {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [selectedRole, setSelectedRole] = useState<string | null>(null)
  const dialogRef = useRef<DialogMethodsRef>()
  const [error, setError] = useState<boolean>(false)
  const [updatedItems, setUpdatedItems] = useState<number[]>([])

  const { refreshData, update } = usePanelRoleMetadata()

  useEffect(() => {
    setShowDialog(true)
  }, [])

  const handleShowDialog = (data: string) => {
    setSelectedRole(data)
    setError(false)
    dialogRef.current.showModal()
  }

  const closeModal = () => {
    dialogRef.current.closeModal()
    setError(false)
    setSelectedRole(null)
    setUpdatedItems([])
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      await update({
        role: selectedRole,
        newPermissions: updatedItems
      })
      await refreshData()
      dialogRef.current.closeModal()
      setError(false)
      setUpdatedItems([])
    } catch (err) {
      setError(true)
    }
  }

  const handleAddUpdatedItem = useCallback(
    (id: number) =>
      setUpdatedItems((prev) => (prev.includes(id) ? prev : [...prev, id])),
    []
  )

  const handleRemoveUpdatedItem = useCallback(
    (id: number) =>
      setUpdatedItems((prev) =>
        prev.includes(id) ? prev.filter((item) => item !== id) : prev
      ),
    []
  )

  return (
    <>
      <main id="kpi-role-metadatas" className="container">
        <div>
          <h1>
            <FormattedMessage id="admin.role-metadata.kpi-title" />
          </h1>
        </div>
        <table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="admin.role-metadata.rol" />
              </th>
            </tr>
          </thead>
          <Suspense fallback={<tbody />}>
            <EditRoleMetadata handleShowDialog={handleShowDialog} />
          </Suspense>
        </table>
      </main>
      {showDialog
        ? createPortal(
            <Dialog ref={dialogRef} className="kpi-role-metadatas-dialog">
              <form onSubmit={handleSubmit}>
                <header>
                  <button type="submit">
                    <FormattedMessage id="edit.dialog.edit.header.apply" />
                  </button>
                  <div
                    role="button"
                    onClick={closeModal}
                    aria-label="Close Filters"
                  >
                    <button className="close"></button>
                  </div>
                </header>
                <h2>{selectedRole}</h2>
                <section>
                  <Suspense>
                    <KpisByRole
                      role={selectedRole}
                      addUpdatedItems={handleAddUpdatedItem}
                      removeUpdatedItem={handleRemoveUpdatedItem}
                    />
                  </Suspense>
                </section>
                {error ? (
                  <p>
                    <FormattedMessage id="error.global.title" />.{' '}
                    <FormattedMessage id="error.global.subtitle" />
                  </p>
                ) : null}
              </form>
            </Dialog>,
            document.body
          )
        : null}
    </>
  )
}
